import React from "react";
import "./effect.scss";
import { useLazyLoadingImage } from '../../hooks/lazyLoadImage';
export const Effect = () => {
  const efficient = useLazyLoadingImage("efficient_robots")
  return (
    <section className="efficient">
      <h3>UV Robots are more efficient</h3>
      <div className="text">
        <p>
          Static UV Lamps are only effective in a certain area around them. The
          light intensity decreases exponentially with distance and is blocked
          by objects.
        </p>
        <p>
          To disinfect all the surfaces it is necessary to move a UVC lamp all
          around a room. UV Robot moves along a predefine route effortlessly.
        </p>
      </div>
      <img src={efficient} alt="efficient-robots" width="800" height="auto" />
    </section>
  );
};
