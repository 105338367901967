import React, { FC } from "react";
import "./title.scss";
export const Title: FC<{ heading: string; subHeading?: string }> = ({
  heading,
  subHeading,
}) => {
  return (
    <div className="title">
      <h1>{heading}</h1>
      {subHeading && <p>{subHeading}</p>}
    </div>
  );
};
