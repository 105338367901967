import { useEffect, useState } from 'react'

export const useLazyLoadingImage = (src:string) => {  
  const ext =window.navigator.vendor.toLowerCase().includes('apple')? 'png':'webp'
  const [sourceLoaded, setSourceLoaded] = useState(`/images/${src}.thumbnail.${ext}`)
  useEffect(() => {
    const img = new Image()
    img.src = `/images/${src}.${ext}`
    img.onload = () => setSourceLoaded(img.src)
  },)
  return sourceLoaded 
}