import React from "react";
import "./gallery.scss";

import image from "../../assets/images/india_flag.svg";
import uk from "../../assets/images/uk_flag.svg";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Gallery = () => {
  const images = [
    useLazyLoadingImage("gallery_1"),
    useLazyLoadingImage("gallery_2"),
    useLazyLoadingImage("gallery_3"),
    useLazyLoadingImage("gallery_4"),
    useLazyLoadingImage("gallery_5"),
  ];
  const aboutUs = useLazyLoadingImage("near_about_us");
  const bg = useLazyLoadingImage("about");
  return (
    <section
      className="gallery"
      style={{
        // @ts-ignore
        "--bg": `url(${bg})`,
      }}
    >
      <div className="content">
        <h3 className="title">Gallery</h3>

        <div className="images">
          {images.map((image, i) => (
            <div
              key={i}
              className={"image" + (i === images.length - 1 ? " last" : "")}
            >
              <img src={image} alt="gallery-1" />
            </div>
          ))}
        </div>

        <div className="designed">
          <div className="flag uk">
            <img src={uk} alt="uk-flag" />
          </div>
          <p>Designed and manufactured in United Kingdom</p>
        </div>
        <div className="about">
          <div className="about_us">
            <div className="quote">
              <p>Robots that can efficiently disinfect hospitals!</p>
              <b> - IEEE Spectrum</b>
            </div>
            <h3 className="subtitle">About us</h3>
            <p>
              We are a team of researchers from IIT Bombay, Imperial College
              London fighting coronavirus with innovation.
            </p>
            <p style={{ textAlign: "left", alignSelf: "flex-start" }}>
              We are part of covidIndiaTaskforce.org and covidFighters.com.
            </p>
          </div>
          <div className="near_about">
            <img src={aboutUs} alt="robot-hospital" />
          </div>
        </div>
      </div>
    </section>
  );
};
