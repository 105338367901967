import React from "react";
import "./product.scss";
import uvVirus from "../../assets/images/uv_virus.svg";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Product = () => {
  const background = useLazyLoadingImage('product')
  const image1 = useLazyLoadingImage("uv_lamp_plus_right");
  const image2 = useLazyLoadingImage("uv_robots_left");
  return (
    <section className="products"
    style={{
      //@ts-ignore
      "--bg":`url(${background})`
    }}
    >
      <h2>Our Products</h2>

      <div className="product product2">
        <div
          className="image"
          style={{
            //@ts-ignore
            "--bg": `url(${image1})`,
          }}
        ></div>
        <div className="desc">
          <h3>
            <span className="blue">UV</span> Lamp
          </h3>
          <ul>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p>
                Stainless Steel body and robust aluminum profile structure with
                FDM 3D Printed parts
              </p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p> Advanced PIR 360° Motion Sensor</p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p> Optional Battery </p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p> 3 years guarantee</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="product product3">
        <div
          className="image"
          style={{
            //@ts-ignore
            "--bg": `url(${image2})`,
          }}
        ></div>
        <div className="desc">
          <h3>
            <span className="blue">UV</span> Robots
          </h3>
          <ul>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p>
                Robust aluminium profile structure with FDM 3D Printed parts.
              </p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p>
                Effective and environment-friendly CE Certified UV disinfection
                Lamps.
              </p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p> Manual switch, automatic turn off after 10 minutes.</p>
            </li>
            <li>
              <img src={uvVirus} width="24" height="24" alt="bullet" />
              <p>
                Motion sensor switch lamp automatically when human is present.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
