import React, { useEffect, useState } from "react";
import "./hero.scss";
import uvRobotsLogo from "../../assets/images/uv_robots_logo.svg";
import { DownloadButton } from "../DownloadButton";
import { useLazyLoadingImage } from '../../hooks/lazyLoadImage';

export const Hero = () => {
  const banner = useLazyLoadingImage('banner')
  return (
    <section
      className="home"
      style={{
        //@ts-ignore
        "--bg": `url(${banner})`,
      }}
    >
      <div className="left">
        <div className="image">
          <img src={uvRobotsLogo} alt="uv-robots-logo" />
        </div>
      </div>
      <div className="right">
        <DownloadButton />
      </div>
    </section>
  );
};
