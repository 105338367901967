import React from "react";

import logo from "../../assets/images/logo.webp";
import { DownloadButton } from "../DownloadButton";
import "./heading.scss";
import {Link} from "react-router-dom";
export const Heading = () => {
  return (
    <header className="header">
      <div className="logo">
          <Link to="/"> <img src={logo} alt="" /> </Link>
      </div>
      <div className="download">
        <DownloadButton />
      </div>
    </header>
  );
};
