import React from "react";
import "./Privacy.scss";
import { Heading } from "../../components/Heading";
export const PrivacyPolicy = () => {
  return (
    <>
      <Heading />
      <main className="main">
        <section className="left">
          <h2>Privacy Policy</h2>
          <div className="articles">
            <article>
              <p>
                We respect the privacy of everyone who visits the Sites. Our
                Privacy Policy is an extension of our commitment to combine the
                highest-quality service with the highest level of integrity in
                dealing with our visitors and customers. This policy was
                developed to inform visitors to the Sites about how we handle
                and use personal information. Privacy is important; we hope you
                take the time to read this Privacy Policy carefully.
                <h4>
                  UNLESS OTHERWISE STATED HEREIN, BY USING THE SITE(S), YOU
                  AGREE TO THE COLLECTION, USE, AND DISCLOSURE OF INFORMATION IN
                  ACCORDANCE WITH THIS PRIVACY POLICY.
                </h4>
              </p>
              <p>
                We will regularly assess this Privacy Policy against new
                technologies, business practices, and our customers’ needs to
                ensure that you are informed to make choices about how to share
                and secure your personal data. If you have questions about this
                privacy statement, our information practices, or your dealings
                with us, please contact us at
                <strong> business@uvrobots.io</strong>
              </p>
              <p>
                When you voluntarily provide it to us, we collect information
                that identifies you <strong>(“Personal Data”)</strong>, such as
                your:
                <ul>
                  <li>
                    <h4>Name;</h4>
                  </li>
                  <li>
                    <h4>Email address;</h4>
                  </li>
                  <li>
                    <h4>Telephone number;</h4>
                  </li>
                  <li>
                    <h4>Email messages to us.</h4>
                  </li>
                  <li>
                    <h4>Location</h4>
                  </li>
                </ul>
              </p>
              <p>
                Broadly speaking, we use Personal Data for the purposes for
                which you provided it. If you engage us for services, we will
                use your name and contact information to fulfill your needs,
                including billing and delivery if applicable. If you send us an
                email, we may use your email address and other information you
                submit to provide customer service to you. We are not in the
                business of selling your Personal Data. We consider this
                information to be a vital part of our relationship with you.
                <strong>
                  Therefore, we will not sell your Personal Data to third
                  parties, including third-party advertisers.
                </strong>
              </p>
              <p>
                We may work with social media sites like Facebook, and with
                application developers who specialize in social commerce so you
                can connect to us, share your interests, express opinions about
                products and services, purchase our services or products, and
                generate interest in our services among members of your social
                networks.
              </p>
              <p>
                If you use third-party social media features, we or the third
                party may collect or share your data, including your Personal
                Data.
                <h4>
                  We consider information collected from or shared through any
                  such social media features as public information, because the
                  third-party social media sites made it publicly available.
                </h4>
              </p>
              <p>
                User data may be employed to customize advertising deliverables,
                such as banners and any other types of advertisements to promote
                our services. Sometimes, these marketing deliverables are
                developed based on user preferences. However, not all personal
                data is used for this purpose. The behavioral retargeting
                technique may also be used to display advertisements tailored to
                user preferences and online behavior.
              </p>
            </article>
          </div>
        </section>
      </main>
    </>
  );
};
