import React from "react";
import "./feature.scss";
import stopwatch from "../../assets/images/stopwatch.svg";
import covid from "../../assets/images/kill_covid.svg";
export const Feature = () => {
  return (
    <section className="features">
      <h3>Features</h3>
      <div className="cards">
        <div className="card">
          <img src={stopwatch} alt="stopwatch" width="120" height="120" />
          <p>Disinfect Viruses in 5 mins</p>
        </div>
        <div className="card">
          <img src={covid} alt="kill-covid" width="120" height="120" />
          <p>Kill Covid19 Viruses</p>
        </div>
      </div>
    </section>
  );
};
