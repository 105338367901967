import React from "react";
import { Effect } from "../../components/Effect";
import { Feature } from "../../components/Feature";
import { Gallery } from "../../components/Gallery";
import { Hero } from "../../components/Hero";
import { Product } from "../../components/Product";
import { Specification } from "../../components/Specification";
import { SpecificationMobile } from "../../components/SpecificationMobile";

import "./home.scss";
import { Video } from "../../components/Video/indes";
// import { Team } from "../Team";
export default function Home() {
  return (
    <>
      <Hero />
      <div style={{margin: "auto", display: "flex", marginTop: 100, justifyContent: "center"}}>
          <iframe width="50%" height="400"
                  src="https://www.youtube.com/embed/Vg-RECSSTlE"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen>
          </iframe>
      </div>

      <Product />
      <Feature />
      <Effect />
      <Gallery />
      <Video />
      {/* <Team /> */}
      <Specification />
      <SpecificationMobile />
    </>
  );
}
