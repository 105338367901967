import React from "react";
import "./press.scss";
import virus from "../../assets/icons/uv_virus.svg";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Press = () => {
  const task = useLazyLoadingImage("covid_task_force");
  const covid = useLazyLoadingImage("covidfighters");
  const toi = useLazyLoadingImage("toi");
  return (
    <section className="press">
      <i>
        <img src={virus} alt="" />
      </i>
      <h3>Press</h3>
      <div className="images">
        <div className="image">
          <img src={task} alt="covid-task-force" />
        </div>
        <div className="image">
          <img src={covid} alt="covid-fighters" />
        </div>
        <div className="image">
          <img src={toi} alt="times-of-india" />
        </div>
      </div>
    </section>
  );
};
