import React from "react";
import "./specification.scss";
import weight from "../../assets/icons/np_weight.svg";
import dimension from "../../assets/icons/np_dimensions.svg";
import battery from "../../assets/icons/np_battery.svg";
import time from "../../assets/icons/np_time.svg";
import output from "../../assets/icons/np_output.svg";
import safety from "../../assets/icons/np_safety.svg";
import lamp from "../../assets/icons/np_lamp.svg";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Specification = () => {
  const features = [
    { Weight: weight },
    { Dimension: dimension },
    { Battery: battery },
    { "Disinfection time": time },
    { Output: output },
    { Safety: safety },
    { Lamp: lamp },
  ];
  const uvLamp = [
    "10 Kg",
    "25 x 25 x 138cm",
    "No battery",
    "20 min per room",
    "300W or 600W",
    "360\u00B0 Motion Sensor",
  ];
  const uvRobot = [
    "40 kg",
    "40 x 40 x 120cm",
    "1kWh, 2h operation",
    "2 min per Room",
    "300W",
    "360\u00B0 Motion Sensor",
  ];
  const philips = useLazyLoadingImage("philips_logo");
  const bg = useLazyLoadingImage("spec");
  return (
    <section
      className="specs"
      style={{
        // @ts-ignore
        "--bg": `url(${bg})`,
      }}
    >
      <div className="center">
        <h2>
          <span className="blue">Technical</span> Specifications
        </h2>
        <div className="table">
          <div className="headings">
            <span className="heading">
              <h3>Features</h3>
            </span>
            <span className="heading">
              <h3>UV Lamp</h3>
            </span>
            <span className="heading">
              <h3>UV Robot</h3>
            </span>
          </div>
          <hr />
          <div className="body">
            <ul>
              {features.map((f, i) => (
                <li
                  key={i}
                  style={{
                    width: "30%",
                    ...(f.Lamp ? { paddingTop: "3rem" } : {}),
                  }}
                >
                  <i>
                    <img src={Object.values(f)[0]} alt="" />
                  </i>
                  <p style={{ maxWidth: "50%" }}>{Object.keys(f)[0]}</p>
                </li>
              ))}
            </ul>
            <ul>
              {uvLamp.map((l, i) => (
                <li key={i} style={{ fontWeight: "bold" }}>
                  {l}
                </li>
              ))}
              <li>
                <div>
                  <img src={philips} alt="" />
                </div>
              </li>
            </ul>
            <ul>
              {uvRobot.map((l, i) => (
                <li key={i} style={{ fontWeight: "bold" }}>
                  {l}
                </li>
              ))}
              <li>
                <div>
                  <img src={philips} alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
