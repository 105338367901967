import React, { useEffect } from "react";
import "./footer.scss";
import email from "../../assets/icons/email.svg";
import loc from "../../assets/icons/location.svg";
import phone from "../../assets/icons/phone.svg";
import { Link } from "react-router-dom";
import insta from "../../assets/images/insta.svg";
import linkdin from "../../assets/images/linkdin.svg";
import tw from "../../assets/images/tw.svg";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
import { useLocation } from "react-router-dom";
export const Footer = () => {
  const footer = useLazyLoadingImage("footer");
  const uvRobot = useLazyLoadingImage("uv_robot");
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // const FooterRender =(pathName)=>{
  //   switch (pathName) {
  //     case value:

  //       break;

  //     default:
  //       break;
  //   }
  // }
  return (
    <footer
      style={{
        // @ts-ignore
        "--bg": `url(${footer})`,
      }}
    >
      <div className="contact">
        <div className="heading">
          <h3>Contact</h3>
        </div>
        <div className="row">
          <ul>
            <li>
              <i>
                <img src={email} alt="" />
              </i>
              <p>michal@uvrobots.io</p>
            </li>
            <li className="address">
              <i>
                <img src={loc} alt="" />
              </i>
              <p>
                1 E Poultry Ave, Farringdon, Farringdon, London EC1A 9PT, UK
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <i>
                <img src={phone} alt="" />
              </i>
              <p>+447428321994</p>
            </li>
            {/*<li className="address">*/}
            {/*  <i>*/}
            {/*    <img src={loc} alt="" />*/}
            {/*  </i>*/}
            {/*  <p>*/}
            {/*    WeWork, Jogeshwari - Vikhroli Link Rd, Andheri East, Mumbai,*/}
            {/*    400076, India*/}
            {/*  </p>*/}
            {/*</li>*/}
          </ul>
        </div>
      </div>
      <div className="footer_about">
        <h3 className="heading">About</h3>
        <div className="row">
          <ul className="team">
            {location.pathname !== "/" && (
              <li>
                <Link to="/">Home</Link>
              </li>
            )}
            {location.pathname !== "/distributors" && (
              <li>
                <Link to="/distributors">Our Distributors</Link>
              </li>
            )}
            {location.pathname !== "/privacypolicy" && (
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </li>
            )}

            {/*{location.pathname !== "/certificates" && (*/}
            {/*  <li>*/}
            {/*    <Link to="/certificates">certificates</Link>*/}
            {/*  </li>*/}
            {/*)}*/}
            {/*{location.pathname !== "/news" && (*/}
            {/*  <li>*/}
            {/*    <Link to="/news">In Press</Link>*/}
            {/*  </li>*/}
            {/*)}*/}
            {/*{location.pathname !== "/story" && (*/}
            {/*  <li>*/}
            {/*    <Link to="/story">Our Story</Link>*/}
            {/*  </li>*/}
            {/*)}*/}
            <li
              style={{ padding: 0, margin: 0, justifyContent: "space-between" }}
            >
              <a
                href="https://www.instagram.com/uvrobots/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/uvrobot"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkdin} alt="" />
              </a>
              <a
                href="https://twitter.com/uvrobots"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={tw} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="image"
        style={{
          // @ts-ignore
          "--bg": `url(${uvRobot})`,
        }}
      ></div>
    </footer>
  );
};
