import React, {useEffect, useState} from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Changelog from "./changelog";
import { News } from "./pages/News";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Footer } from "./components/Footer";
import { Press } from "./components/Press";
import { Certificate } from "./pages/Certificates";
import { Distributors } from "./pages/Distributors";

function App() {
  const [changelog, setChangelog] = useState(false);


  useEffect(() => {
    // add HubSpot analytics
    const script = document.createElement("script");

    script.src = "https://js.hs-scripts.com/19519216.js";
    script.async = true;

    document.body.appendChild(script);
  });

  return (
    <>
      <div>
        <title>UV Robots</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.webp"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Source+Sans+Pro:wght@400;600&display=swap"
          rel="stylesheet"
        ></link>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="facebook-domain-verification" content="sti5ssr8p1azssst5bsp18l4oovq5a" />
      </div>
      {changelog && <Changelog close={() => setChangelog(false)} />}
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
            {window.screen.width <= 520 && <Press />}
          </Route>
          <Route path="/news">
            <News />
            <Press />
          </Route>
          <Route path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route path="/certificates">
            <Certificate />
          </Route>
          <Route path="/distributors">
            <Distributors />
            {window.screen.width > 520 && <Press />}
          </Route>
          {/* <Route path="/story">
            <Story />
          </Route> */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
