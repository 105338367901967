import React from "react";
import "./video.scss";
import videoPlaceholder from "../../assets/images/videoPlaceholder.webp";
export const Video = () => {
  return (
    <section className="video">
      <div className="video-card">
        <video poster={videoPlaceholder} loop autoPlay muted>
          <source src="/videos/robot-video.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
};
