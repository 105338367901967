import React from "react";
import { Heading } from "../../components/Heading";
import "./certificate.scss";
import { Title } from "../../components/Title";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Certificate = () => {
  const certificates = [
    useLazyLoadingImage("/certificates/image1"),
    useLazyLoadingImage("/certificates/image2"),
    useLazyLoadingImage("/certificates/image3"),
    useLazyLoadingImage("/certificates/image4"),
    useLazyLoadingImage("/certificates/image5"),
    useLazyLoadingImage("/certificates/image6"),
    useLazyLoadingImage("/certificates/image7"),
    useLazyLoadingImage("/certificates/image8"),
  ];
  return (
    <>
      <Heading />
      <main className="certificate-main">
        <Title heading={"Certifications"} />
        <section className="certificates">
          {certificates.map((certificate, i) => (
            <div key={i} className="image">
              <a
                target="_blank"
                href={i <= 4 ? "/TestReport.pdf" : "/UVRobots.pdf"}
                rel="noopener noreferrer"
                download
              >
                <img src={certificate} alt="" />
              </a>
            </div>
          ))}
        </section>
      </main>
    </>
  );
};
