import React from "react";
import "./downloadButton.scss";

export const DownloadButton = () => {
  return (
    <div className="download_button">
      <a
        target="_blank"
        href="/brochure.pdf"
        rel="noopener noreferrer"
        download
      >
        Download Brochure
      </a>
    </div>
  );
};
