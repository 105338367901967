import React from "react";
import "./news.scss";
import { Heading } from "../../components/Heading";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const News = () => {
  const toi = useLazyLoadingImage("toi");
  return (
    <>
      <Heading />
      <main className="main">
        <section className="right">
          <div className="toi">
            <img src={toi} alt="times of india" />
          </div>
          <pre>TNN | Jul 5, 2020, 04.33 AM IST</pre>
        </section>
        <section className="left">
          <h2>AMC to use UV bots for sanitising quarantine centres</h2>
          <div className="articles">
            <article>
              <p>
                Aurangabad: The civic administration is planning to deploy bots
                emitting UV rays that will disinfect and sanitise institutional
                quarantine (IQ) centres and Covid care centres (CCC) in a span
                of 10 minutes. As per authorities, this is a first-of-its-kind
                initiative taken by any civic body in the state.
              </p>
              <p>
                It usually takes around 24 hours to disinfect a 20 square metre
                room occupied by a Covid positive patient. The latest technology
                will not only reduce the duration of exposure to sodium
                hypochlorite and isopropyl, it will also save time and money.
              </p>
              <p>
                Aurangabad municipal administrator Astik Kumar Pandey said,
                “After discharging every positive patient from IQ or CCC
                facilities, we have to sanitize the room and keep it vacant for
                24 hours before accommodating another patient. From now on, we
                are going to use UV technology which will not take more than 20
                minutes to sanitise a room.
              </p>
            </article>
            <article>
              <p>
                This will help in ensuring that a room is readily available for
                a patient as soon as the previos patient gets discharged.” Those
                privy to the initiative said that each UV bot costs around Rs
                50,000 per unit.
              </p>
              <p>
                Smart city deputy CEO Pushkal Shivam said the bots could also be
                used for city buses which could be sanitised at the end of every
                trip.
              </p>
              <p>
                Pravee Tayal, who introduced the concept to the civic body, said
                the UV rays directly destroys DNA, RNA and proteins, causing the
                virus cells to die and rendering them inefficient to reproduce
                and replicate. “It is a non-mutating technology, and comes with
                a life of about 10, 000 hours,” said Tayal
              </p>
            </article>
          </div>
        </section>
      </main>
    </>
  );
};
