import React from "react";
import { Heading } from "../../components/Heading";
import "./distributors.scss";

import email from "../../assets/icons/envelope.svg";
import location from "../../assets/icons/pin.svg";
import phone from "../../assets/icons/phone-call.svg";
import { Title } from "../../components/Title";
import { useLazyLoadingImage } from "../../hooks/lazyLoadImage";
export const Distributors = () => {
  const bg = useLazyLoadingImage("product");
  const map = useLazyLoadingImage("map");
  return (
    <>
      <Heading />
      <section className="distributors">
        <Title
          heading={"Our Distributor"}
          subHeading={`We have robotic and software engineers based in London which will
            provide full support to our service.`}
        />
        <main
          className="content"
          style={{
            // @ts-ignore
            "--bg": `url(${bg})`,
          }}
        >
          <div>
            <img src={map} alt="" />
          </div>
          <div className="diss">
            <div className="dis">
              <h2>India</h2>
              <h4>Aleta Technologies</h4>
              <ul>
                <li>
                  <i>
                    <img src={email} alt="" />
                  </i>
                  <p>kr@aleta.co.in</p>
                </li>
                <li>
                  <i>
                    <img src={phone} alt="" />
                  </i>
                  <p>+91 9324601955</p>
                </li>
                <li className="address">
                  <i>
                    <img src={location} alt="" />
                  </i>
                  <p>Mumbai</p>
                </li>
              </ul>
            </div>
            <div className="dis">
              <h2>Austria</h2>
              <h4>Covid Fighters</h4>{" "}
              <ul>
                <li>
                  <i>
                    <img src={email} alt="" />
                  </i>
                  <p>support@covidfighters.com</p>
                </li>
                <li>
                  <i>
                    <img src={phone} alt="" />
                  </i>
                  <p>+43 67763826930</p>
                </li>
                <li className="address">
                  <i>
                    <img src={location} alt="" />
                  </i>
                  <p>Vienna</p>
                </li>
              </ul>
            </div>
            <div className="dis">
              <h2>Aviation Industry</h2>
              <h4>Troyavi</h4>{" "}
              <ul>
                <li>
                  <i>
                    <img src={email} alt="" />
                  </i>
                  <p> </p>
                </li>
                <li>
                  <i>
                    <img src={phone} alt="" />
                  </i>
                  <p>+971 43628000</p>
                </li>
                <li className="address">
                  <i>
                    <img src={location} alt="" />
                  </i>
                  <p>Dubai</p>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};
