import React from "react";

const text = `
Once you've read this, click the button, and this thing will disappear.

Changelog(Done): 
- Responsive complete, most things look okay on mobile, tab, and desktop, only widescreen monitors and 4K are a concern now
- Possibly there are inconsistencies tho, which will be fixed as encountered.
- Restored font sizes to default, so it's more consistent, will need to figure out a good hierarchy tho. 

- Design for tabs almost complete, some minor mistakes remain.
- Resized and cropped images of the products, so they are a square, they were not exported correctly before, due to avocode?! 
- Added this quick changelog thing, to help understand what I'm doing right now, and what changes were made since then. 
- Working on responsiveness, not complete yet, also fine tuning the CSS.

Todo:
- Add a navigation header, which should be a must for a good UX
- Download button will be awkward on widescreen monitors so shift everything to center maybe
- Remove unnecessary CSS, there's a lot.
- Bruh, add animations. Slick ones. 
- Extract critical CSS, so first contentful paint is done ASAP.
- Make site look good even with no css.
- Colors are kinda dull, don't know but site looks like it's from 2013 
- Yes, if everything's done, then bow down before IE11? :(
  
Trash/Done:
- Inter is a better font, see how that one looks (=> Doesn't look good with this design)
- Figure out what to do with the product specs table, when the screen is not enough to compare those two products side by side. (=> Hide second column for mobiles)
`;

interface ChangelogProps {
  close: () => void;
}
export default function Changelog({ close }: ChangelogProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <h3>Changelog</h3>
      <textarea
        value={text}
        style={{
          width: "70vw",
          minWidth: "340px",
          margin: "2em auto",
          height: "40vh",
          resize: "none",
          overflowX: "hidden",
        }}
        cols={10}
        readOnly
      ></textarea>
      <button
        onClick={close}
        style={{
          border: "none",
          width: "280px",
          height: "50px",
          color: "white",
          backgroundColor: "teal",
          borderRadius: "4px",
          fontSize: "1rem",
          margin: "2em auto",
        }}
      >
        Okay!
      </button>
    </div>
  );
}
